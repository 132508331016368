import React, { useEffect, Suspense, lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
const Home = lazy(() => import('./containers/Home'));
const Catmonials = lazy(() => import('./containers/Catmonials'));
const About = lazy(() => import('./containers/About'));
const FeedYourCat = lazy(() => import('./containers/FeedYourCat'));
const WhereToBuy = lazy(() => import('./containers/WhereToBuy'));
const FurentingGuide = lazy(() => import('./containers/FurentingGuide'));
const FurentingGuideArticle = lazy(() => import('./containers/FurentingGuideArticle'));
const Contact = lazy(() => import('./containers/Contact'));
const FAQs = lazy(() => import('./containers/FAQs'));
const SamplingForm = lazy(() => import('./containers/SamplingForm'));
const SamplingFormSubmitted = lazy(() => import('./containers/SamplingFormSubmitted'));
const DataPrivacy = lazy(() => import('./containers/DataPrivacy'));
const CorporateDataPrivacy = lazy(() => import('./containers/CorporateDataPrivacy'));
const NotFound = lazy(() => import('./containers/NotFound'));
const ScrollToTop = lazy(() => import('./components/ScrollToTop'));

export default function Routes() {
	const location = useLocation();	

	async function waitForInit() {		
		return new Promise((res, rej) => {			
			const hasGTagLoaded = () => {				
				if(window.gtag) {
					res();
					onLoad();
				} else {					
					setTimeout(hasGTagLoaded, 300);
				}
			}

			hasGTagLoaded();
		});
	}

	function onLoad() {		
		window.gtag('page', location.pathname);		
	}

	useEffect(() => {	
		waitForInit();
	},[location]);

	return (
		<>			
			<Suspense fallback={<div />}>
				<ScrollToTop />		
			</Suspense>	
			<Switch>				
				<Route exact path="/">					
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<Home />					
					</Suspense>
				</Route>
				<Route exact path="/catmonials">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<Catmonials />
					</Suspense>
				</Route>
				<Route exact path="/about">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<About />
					</Suspense>
				</Route>
				<Route exact path="/feedYourCat">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FeedYourCat />
					</Suspense>
				</Route>
				<Route exact path="/whereToBuy">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<WhereToBuy />
					</Suspense>
				</Route>
				<Route exact path="/furentingGuide">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FurentingGuide />
					</Suspense>
				</Route>
				<Route exact path="/furentingGuide/:article">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FurentingGuideArticle />
					</Suspense>
				</Route>
				<Route exact path="/contact">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<Contact />
					</Suspense>
				</Route>
				<Route exact path="/faqs">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FAQs />
					</Suspense>
				</Route>
				<Route exact path="/dataPrivacy">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<DataPrivacy />
					</Suspense>
				</Route>
				<Route exact path="/corporateDataPrivacy">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<CorporateDataPrivacy />
					</Suspense>
				</Route>
				<Route exact path="/samplingForm">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<SamplingForm />
					</Suspense>
				</Route>
				<Route exact path="/samplingFormSubmitted">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<SamplingFormSubmitted />
					</Suspense>
				</Route>
				<Route exact path="/pageNotFound">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<NotFound />
					</Suspense>
				</Route>
				<Route>
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<NotFound />
					</Suspense>
				</Route>
			</Switch>						
		</>
	);
}