const config = {
	social: {
		FB: "490011015577568",
		beta: "192833092699583",
		localhost:"841783243090112"
	},
	//domain: "https://122.248.241.65/"	
	//domain: "https://goodestcat.herokuapp.com/"
	domain: "https://goodestpetfood.com/",
	//domain: "https://13.212.84.31/", //beta version
	ga_id: "G-7LET3386HJ"
}

export default config;