import React, { useState, useEffect, Suspense, lazy } from 'react';
import Routes from './Routes';
import { AppContext } from './libs/contextLib';
import config from './config';
import Header from './containers/Header';
const BuyNowButton = lazy(() => import('./components/BuyNowButton'));
const Footer = lazy(() => import('./containers/Footer'));

/*global FB*/

function App() { 
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);
  const [fbUser, setFBUser] = useState(null);
  const [fbAppId, setFBAppId] = useState(null);  

  useEffect(() => {      
    if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {      
      //console.log('local');
      setFBAppId(config.social.localhost);            
    } else if (window.location.hostname === 'goodestpetfood.com') {
      //console.log('goodestpetfood.com')
      setFBAppId(config.social.FB);      
    } else {
      //console.log('beta');
      setFBAppId(config.social.beta);            
    }    

    onLoad();

  }, [fbAppId]);

  async function onLoad() {            
    loadFacebookSDK();
  }  

  function loadFacebookSDK() {

    window.fbAsyncInit = function() {        
        FB.init({
          appId      : fbAppId,
          cookie     : true,
          xfbml      : true,
          version    : 'v10.0'
        });        
    };        

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));   
  }  
 
  return (  
      <div className="App">      
        <AppContext.Provider value={{isAuthenticated, userHasAuthenticated, userFirstName, setUserFirstName, fbUser, setFBUser}}>          
            <Header />          
            <Suspense fallback={<div />}>
              <BuyNowButton />
            </Suspense>          
            <Routes />          
            <Suspense fallback={<div />}>
              <Footer />          
            </Suspense>
        </AppContext.Provider>      
      </div>  
  );
}

export default App;
