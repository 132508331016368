import React, { useState, useEffect, Suspense, lazy  } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';
const RegistrationPopup = lazy(() => import('./RegistrationPopup'));

/*global FB*/

export default function Header() {	
	const { isAuthenticated, userHasAuthenticated, userFirstName, setUserFirstName, setFBUser } = useAppContext();	
	const [windowDimension, setWindowDimension] = useState(window.innerWidth);
	const isMobile = windowDimension <= 845;	
	//const isMobile = windowDimension <= 730;	

	async function waitForInit() {		
		return new Promise((res, rej) => {
			const hasFBLoaded = () => {
				if(window.FB) {					
					res();
					onLoad();					
				} else {					
					setTimeout(hasFBLoaded, 300);
				}
			}

			hasFBLoaded();
		});
	}

	useEffect(() => {	
		setWindowDimension(window.innerWidth);

		waitForInit();				

		function handleResize() {
			setWindowDimension(window.innerWidth);

			let navLinks = document.getElementById('navLinks');			
			
			if(window.innerWidth >= 845) {				
				navLinks.style.display = 'block';
			} else {				
				navLinks.style.display = 'none';
			}
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [userFirstName]);	

	async function handleFBResponse(data) {
		window.FB.api('/me?fields=name,first_name,email,picture', (response) => {						
			setUserFirstName(response.first_name);
			setFBUser({
				name: response.name,
				email: response.email,
				picture: response.picture.data.url
			});
		});

	}

	async function onLoad() {		
		FB.getLoginStatus((response) => {			
			if(response.status == 'connected') {				
				userHasAuthenticated(true);
				handleFBResponse(response.authResponse);
			}
		});		
	}

	async function signOutHandler(e) {
		e.preventDefault();
		
		FB.logout((res) => {			
			userHasAuthenticated(false);
		});		
	}

	function toggleNav() {
		let navLinks = document.getElementById('navLinks');

		if(navLinks.style.display == 'block') {
			navLinks.style.display = 'none';
		} else {
			navLinks.style.display = 'block';
		}
	}

	function renderMobileNav() {		
		return (				
			<nav>
				<ul id="navLinks">
					<li><NavLink to="/about" onClick={toggleNav}>About</NavLink></li>
					<li><NavLink to="/feedYourCat" onClick={toggleNav}>Feed Your Cat</NavLink></li>
					<li><NavLink to="/furentingGuide" onClick={toggleNav}>Furenting Guide</NavLink></li>
					<li><NavLink to="/whereToBuy" onClick={toggleNav}>Where to Buy</NavLink></li>
					<li><NavLink to="/faqs" onClick={toggleNav}>FAQs</NavLink></li>
					<li><NavLink to="/contact" onClick={toggleNav}>Contact Us</NavLink></li>
				</ul>
				<a className="navIcon" onClick={toggleNav}>
					<svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
						<path id="ic_menu_24px" d="M3,18H21V16H3Zm0-5H21V11H3ZM3,6V8H21V6Z" transform="translate(-3 -6)" fill="#FFF"/>
					</svg>
				</a>
			</nav>
		)
	}

	function renderDesktopNav() {		
		return (
			<nav>
				<ul id="navLinks">
					<li><NavLink to="/about">About</NavLink></li>
					<li><NavLink to="/feedYourCat">Feed Your Cat</NavLink></li>
					<li><NavLink to="/furentingGuide">Furenting Guide</NavLink></li>
					<li><NavLink to="/whereToBuy">Where to Buy</NavLink></li>
					<li><NavLink to="/faqs">FAQs</NavLink></li>
					<li><NavLink to="/contact">Contact Us</NavLink></li>
				</ul>
			</nav>
		);
	}

	return (
		<header>			
			<div className="signInBar">
				<ul>
					{isAuthenticated ? (
						<li>							
							Hi, {userFirstName} | <a href="#" onClick={signOutHandler}>Logout</a>
						</li>
					) : (
						<li>
							<Suspense fallback={<div />}>
								<RegistrationPopup />
							</Suspense>
						</li>
					)}										
				</ul>
			</div>
			<div className="navbar">
				<div className="logoHolder">
					<NavLink to="/">
						<img src="/images/goodestCatLogo.gif" width="320" height="54" />						
					</NavLink>
				</div>
				{ isMobile ? renderMobileNav() : renderDesktopNav()}				
			</div>      			
		</header>
	);
}